<template>
    <table-container>
        <data-iterator
            ref="iterator"
            :api="api"
            :filters.sync="filters"
            @update:filters="updateFilters"
        >
            <template v-slot:filters="{ busy }">
                <table-filters
                    v-model="filters"
                    :fields="headers"
                    :busy="busy"
                    :list-filters="listFilters"
                    @input="updateFilters"
                ></table-filters>
            </template>

            <template v-slot:actions>
                <slot name="actions"></slot>
            </template>

            <template slot-scope="{ items }">
                <d-table
                    :items="items"
                    :headers="headers"
                    :filters.sync="filters"
                    row-select
                    row-action
                    @onRowSelected="onRowSelected"
                >
                    <!-- name column -->
                    <template v-slot:[`item.name`]="{ item }">
                        <router-link :to="getEditLink(item)">
                            {{ item.name }}
                        </router-link>
                    </template>

                    <template v-slot:[`item.approveEmail`]="{ item }">
                        <v-chip v-if="item.approveEmail">
                            {{ item.approveEmail }}
                        </v-chip>
                        <v-chip
                            v-for="email in item.additionalApproveEmails"
                            :key="email"
                        >
                            {{ email }}
                        </v-chip>
                    </template>

                    <!-- active -->
                    <template v-slot:[`item.active`]="{ item }">
                        <checkbox-input
                            v-if="showActivateCheckboxes"
                            :value="item.isActive"
                            :disabled="!item.isEnabledForRestaurantAdmin"
                            @input="
                                item.isActive
                                    ? confirmDeactivation(item)
                                    : confirmActivation(item)
                            "
                        ></checkbox-input>
                    </template>

                    <!-- isEnabledForRestaurantAdmin -->
                    <template v-slot:[`item.restaurantAdmin`]="{ item }">
                        <checkbox-input
                            v-if="showAdminCheckboxes"
                            :value="item.isEnabledForRestaurantAdmin"
                            :disabled="!isSuperAdmin"
                            @input="
                                item.isEnabledForRestaurantAdmin
                                    ? confirmRestaurantAdminDisable(item)
                                    : confirmRestaurantAdminEnable(item)
                            "
                        ></checkbox-input>
                    </template>

                    <!-- row actions -->
                    <template v-slot:rowActions="{ item }">
                        <edit-button :to="getEditLink(item)"></edit-button>
                        <edit-button
                            :tooltip="$t('labels.delivery_settings')"
                            icon="motorcycle"
                            :to="getEditLink(item, 3)"
                        ></edit-button>
                        <edit-button
                            :tooltip="$t('labels.menu')"
                            icon="restaurant"
                            :to="getEditLink(item, 5)"
                        ></edit-button>
                        <delete-action
                            :entity-name="item.name"
                            :entity-id="item.id"
                            :api="api"
                            @deleted="onEntityDeleted"
                            @failed="onEntityDeleteFailed"
                        ></delete-action>
                    </template>
                </d-table>
            </template>

            <!-- batch actions -->
            <template v-slot:batchActions>
                <delete-batch-action
                    :selected-ids="selectedIds"
                    :api="api"
                    @finished="onBatchDeleteFinished"
                    @finishedWithErrors="onBatchDeleteFinishedWithErrors"
                    @canceled="onBatchDeleteCanceled"
                ></delete-batch-action>
            </template>
        </data-iterator>

        <confirm-modal
            :value="confirmActivationModal"
            :text="
                $t('texts.confirm_restaurant_activation', {
                    restaurant: itemSelectedForActivation
                        ? itemSelectedForActivation.name
                        : '',
                })
            "
            :processing="processingActivation"
            @onApprove="activateEntity"
            @onCancel="resetActiveCheckboxes"
        ></confirm-modal>
        <confirm-modal
            :value="confirmDeactivationModal"
            :text="
                $t('texts.confirm_restaurant_deactivation', {
                    restaurant: itemSelectedForActivation
                        ? itemSelectedForActivation.name
                        : '',
                })
            "
            :processing="processingActivation"
            @onApprove="deactivateEntity"
            @onCancel="resetActiveCheckboxes"
        ></confirm-modal>

        <confirm-modal
            :value="showAdminEnableModal"
            :text="
                $t('texts.confirm_restaurant_admin_enable', {
                    restaurant: itemSelectedForAdminChange
                        ? itemSelectedForAdminChange.name
                        : '',
                })
            "
            :processing="processingAdminChange"
            @onApprove="restaurantAdminEnable"
            @onCancel="resetAdminCheckboxes"
        ></confirm-modal>
        <confirm-modal
            :value="showAdminDisableModal"
            :text="
                $t('texts.confirm_restaurant_admin_disable', {
                    restaurant: itemSelectedForAdminChange
                        ? itemSelectedForAdminChange.name
                        : '',
                })
            "
            :processing="processingAdminChange"
            @onApprove="restaurantAdminDisable"
            @onCancel="resetAdminCheckboxes"
        ></confirm-modal>
    </table-container>
</template>

<script>
import CloneDeep from 'lodash/cloneDeep'
import routeType from '@/router/routeType'
import RestApiType from '@/api/RestApiType'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import ActivateMixin from '@/components/mixins/ActivateMixin'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import ConfirmModal from '@/components/confirm/ConfirmModal'
import RestaurantsApiClient from '@/api/RestApi/RestaurantsApiClient'
import Headers from '@/pages/restaurants/mixins/Headers'
import ListFilters from '@/pages/restaurants/mixins/ListFilters'
import AuthMixin from '@/components/mixins/AuthMixin'
import RestApiCollection from '@/api/RestApiCollection'
import Notification from '@/services/Notification/Notification'

export default {
    components: { ConfirmModal, CheckboxInput },
    mixins: [
        DataIteratorMixin,
        DataTableMixin,
        ActivateMixin,
        Headers,
        ListFilters,
        AuthMixin,
    ],
    data() {
        return {
            api: RestApiType.RESTAURANTS,
            showAdminEnableModal: false,
            showAdminDisableModal: false,
            processingAdminChange: false,
            itemSelectedForAdminChange: null,
            showAdminCheckboxes: true,
        }
    },
    computed: {
        tableName() {
            return this.$t('labels.restaurants')
        },
    },
    methods: {
        getEditLink(item, tab = 0) {
            return {
                name: routeType.RESTAURANT_EDIT,
                params: { id: item.id },
                query: { tab: tab },
            }
        },

        copyItem(item) {
            RestaurantsApiClient.clone(item.id).then(() => {
                this.$set(this.filters, '_search', { value: item.name })
            })
        },

        confirmRestaurantAdminEnable(item) {
            this.itemSelectedForAdminChange = CloneDeep(item)
            this.showAdminEnableModal = true
        },

        confirmRestaurantAdminDisable(item) {
            this.itemSelectedForAdminChange = CloneDeep(item)
            this.showAdminDisableModal = true
        },

        restaurantAdminEnable() {
            this.processingAdminChange = true
            RestApiCollection.get(this.api)
                .restaurantAdminEnable(this.itemSelectedForAdminChange.id)
                .then(() => {
                    Notification.restaurantAdminEnabled(
                        'labels.restaurant',
                        this.itemSelectedForAdminChange.name
                    )
                })
                .finally(() => {
                    this.showAdminEnableModal = false
                    this.processingAdminChange = false
                    this.reloadAfterActivationChanges()
                })
        },

        restaurantAdminDisable() {
            this.processingAdminChange = true
            RestApiCollection.get(this.api)
                .restaurantAdminDisable(this.itemSelectedForAdminChange.id)
                .then(() => {
                    Notification.restaurantAdminDisabled(
                        'labels.restaurant',
                        this.itemSelectedForAdminChange.name
                    )
                })
                .finally(() => {
                    this.showAdminDisableModal = false
                    this.processingAdminChange = false
                    this.reloadAfterActivationChanges()
                })
        },

        resetAdminCheckboxes() {
            this.showAdminEnableModal = false
            this.showAdminDisableModal = false
            this.showAdminCheckboxes = false

            this.$nextTick(() => {
                this.showAdminCheckboxes = true
            })
        },

        reloadAfterActivationChanges() {
            this.$refs.iterator.updateItems(true).then(() => {
                this.resetActiveCheckboxes()
                this.showActivateCheckboxes = true
                this.resetAdminCheckboxes()
                this.showAdminCheckboxes = true
            })
        },
    },
}
</script>
