<script>
import PageTitle from '@/components/layout/components/pageTitle'
import routeType from '@/router/routeType'
import AuthMixin from '@/components/mixins/AuthMixin'
import RestaurantTable from '@/components/pages/restaurants/Table'
import CreateButton from '@/components/form/Actions/CreateButton'
import Export from '@/pages/restaurants/data/Export'

export default {
    name: 'RestaurantsList',
    components: {
        Export,
        PageTitle,
        RestaurantTable,
        CreateButton,
    },
    mixins: [AuthMixin],
    data() {
        return {
            routeType,
            selectedFilters: {},
            selectedItems: [],
        }
    },
}
</script>

<template lang="pug">
    div
        page-title {{ $t('restaurants.list_page_title') }}
        restaurant-table(:selected-filters.sync="selectedFilters" :selected-items.sync="selectedItems")
            template(v-slot:actions)
                create-button(:route-name="routeType.RESTAURANT_CREATE" tabbed)
        portal(to="footer")
            export.mr-2(v-if="isSuperAdmin" :selected-filters="selectedFilters" :selected-items="selectedItems")
</template>
