export default {
    computed: {
        listFilters() {
            return [
                {
                    key: 'isActive',
                    label: 'labels.deactivated',
                    value: 'false',
                    inputType: 'checkbox',
                },
            ]
        },
    },
}
