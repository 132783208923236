var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-container',[_c('data-iterator',{ref:"iterator",attrs:{"api":_vm.api,"filters":_vm.filters},on:{"update:filters":[function($event){_vm.filters=$event},_vm.updateFilters]},scopedSlots:_vm._u([{key:"filters",fn:function(ref){
var busy = ref.busy;
return [_c('table-filters',{attrs:{"fields":_vm.headers,"busy":busy,"list-filters":_vm.listFilters},on:{"input":_vm.updateFilters},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})]}},{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('d-table',{attrs:{"items":items,"headers":_vm.headers,"filters":_vm.filters,"row-select":"","row-action":""},on:{"update:filters":function($event){_vm.filters=$event},"onRowSelected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.getEditLink(item)}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.approveEmail",fn:function(ref){
var item = ref.item;
return [(item.approveEmail)?_c('v-chip',[_vm._v(" "+_vm._s(item.approveEmail)+" ")]):_vm._e(),_vm._l((item.additionalApproveEmails),function(email){return _c('v-chip',{key:email},[_vm._v(" "+_vm._s(email)+" ")])})]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [(_vm.showActivateCheckboxes)?_c('checkbox-input',{attrs:{"value":item.isActive,"disabled":!item.isEnabledForRestaurantAdmin},on:{"input":function($event){item.isActive
                                ? _vm.confirmDeactivation(item)
                                : _vm.confirmActivation(item)}}}):_vm._e()]}},{key:"item.restaurantAdmin",fn:function(ref){
                                var item = ref.item;
return [(_vm.showAdminCheckboxes)?_c('checkbox-input',{attrs:{"value":item.isEnabledForRestaurantAdmin,"disabled":!_vm.isSuperAdmin},on:{"input":function($event){item.isEnabledForRestaurantAdmin
                                ? _vm.confirmRestaurantAdminDisable(item)
                                : _vm.confirmRestaurantAdminEnable(item)}}}):_vm._e()]}},{key:"rowActions",fn:function(ref){
                                var item = ref.item;
return [_c('edit-button',{attrs:{"to":_vm.getEditLink(item)}}),_c('edit-button',{attrs:{"tooltip":_vm.$t('labels.delivery_settings'),"icon":"motorcycle","to":_vm.getEditLink(item, 3)}}),_c('edit-button',{attrs:{"tooltip":_vm.$t('labels.menu'),"icon":"restaurant","to":_vm.getEditLink(item, 5)}}),_c('delete-action',{attrs:{"entity-name":item.name,"entity-id":item.id,"api":_vm.api},on:{"deleted":_vm.onEntityDeleted,"failed":_vm.onEntityDeleteFailed}})]}}],null,true)})]}},{key:"batchActions",fn:function(){return [_c('delete-batch-action',{attrs:{"selected-ids":_vm.selectedIds,"api":_vm.api},on:{"finished":_vm.onBatchDeleteFinished,"finishedWithErrors":_vm.onBatchDeleteFinishedWithErrors,"canceled":_vm.onBatchDeleteCanceled}})]},proxy:true}],null,true)}),_c('confirm-modal',{attrs:{"value":_vm.confirmActivationModal,"text":_vm.$t('texts.confirm_restaurant_activation', {
                restaurant: _vm.itemSelectedForActivation
                    ? _vm.itemSelectedForActivation.name
                    : '',
            }),"processing":_vm.processingActivation},on:{"onApprove":_vm.activateEntity,"onCancel":_vm.resetActiveCheckboxes}}),_c('confirm-modal',{attrs:{"value":_vm.confirmDeactivationModal,"text":_vm.$t('texts.confirm_restaurant_deactivation', {
                restaurant: _vm.itemSelectedForActivation
                    ? _vm.itemSelectedForActivation.name
                    : '',
            }),"processing":_vm.processingActivation},on:{"onApprove":_vm.deactivateEntity,"onCancel":_vm.resetActiveCheckboxes}}),_c('confirm-modal',{attrs:{"value":_vm.showAdminEnableModal,"text":_vm.$t('texts.confirm_restaurant_admin_enable', {
                restaurant: _vm.itemSelectedForAdminChange
                    ? _vm.itemSelectedForAdminChange.name
                    : '',
            }),"processing":_vm.processingAdminChange},on:{"onApprove":_vm.restaurantAdminEnable,"onCancel":_vm.resetAdminCheckboxes}}),_c('confirm-modal',{attrs:{"value":_vm.showAdminDisableModal,"text":_vm.$t('texts.confirm_restaurant_admin_disable', {
                restaurant: _vm.itemSelectedForAdminChange
                    ? _vm.itemSelectedForAdminChange.name
                    : '',
            }),"processing":_vm.processingAdminChange},on:{"onApprove":_vm.restaurantAdminDisable,"onCancel":_vm.resetAdminCheckboxes}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }