export default {
    computed: {
        headers() {
            return [
                {
                    value: 'id',
                    text: this.$t('labels.id'),
                    type: 'string',
                },
                {
                    value: 'name',
                    text: this.$t('labels.name'),
                    type: 'string',
                },
                {
                    value: 'phone',
                    text: this.$t('labels.phone'),
                    type: 'string',
                },
                {
                    value: 'approveEmail',
                    text: this.$t('labels.confirmationEmail'),
                    type: 'string',
                },
                {
                    value: 'active',
                    text: this.$t('labels.active'),
                },
                {
                    value: 'restaurantAdmin',
                    text: this.$t('labels.list_restaurant_admin'),
                },
            ]
        },
    },
}
